$dark-color: #4d4d4f;
$white-grey: #ececec;
$white: #fff;
$btn-outline: 5px auto #e6e7e8;
$spacer-padding-bottom: 96px;
$fluid-padding-left: 8%;
$fluid-padding-right: 8%;
$primary: #f6a415;
$secondary: #00a79d;

$btn-focus-width: 0;

/* forms.scss */
$input-color: $dark-color;
$input-bg: $white-grey;

$link-color: $secondary;
$link-hover-color: $dark-color;

$font-family-base: 'Montserrat', sans-serif;

$navbar-light-color: $dark-color;
$navbar-light-hover-color: $secondary;


@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import '~@fortawesome/fontawesome-free/scss/solid';

@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&subset=latin-ext');


$btn-primary-bg: #f6a415;
$btn-primary-border: #f6a415;
$btn-primary-pseudo-bg: #00a79d;
$btn-primary-pseudo-border: #00a79d;

.btn-primary {
  @include button-variant($btn-primary-bg, darken($btn-primary-border, 0%), darken($btn-primary-pseudo-bg, 0%),
          lighten($btn-primary-pseudo-border, 0%), lighten($btn-primary-pseudo-border, 0%), darken($btn-primary-pseudo-bg, 0%));
}

$theme-colors: (
        "dark-color": $dark-color,
        "white-grey": $white-grey,
);


html {
  scroll-behavior: smooth;
}


.container-fluid {
  padding-right: $fluid-padding-right;
  padding-left: $fluid-padding-left;
}

#rolunk, .machine-box, .career, .contact, .team, .blog, .wysiwyg-inner {
  padding-bottom: $spacer-padding-bottom;
}

hr {
  border-top: 1px solid #fff;
}

//.brw-container {
//  padding: 0 8rem 96px 8rem;
//}

.btn {

  &:focus,
  &.focus {
    outline: $btn-outline;
  }
}


.brw-btn {
  margin-top: 90px;
  font-size: 1.75rem;
}

.brw-sec-btn {
  padding: 15px;
  margin-top: 35px;
  vertical-align: middle;
  display: inline-block;
  letter-spacing: 2px;

  &.btn-primary {
    color: $white;
    border: {
      top: 1px solid $white;
      bottom: 1px solid $white;
    }
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;

    &:hover,
    &.hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: transparent;
      border: 1px solid $white;
    }

    &:not(:disabled):not(.disabled) {
      &.active,
      &:active,
      &:hover,
      &.focus,
      &:focus,
      &:active:focus {
        background-color: transparent;
        border: 1px solid $white;
      }
    }
  }
}


.section-headline {
  letter-spacing: 20px;
  text-transform: uppercase;
  color: #4d4d4f;
  text-align: center;
  padding: 96px 0 96px 0;
  width: 100%;

  hr.schr {
    width: 155px;
    height: 5px;
    background-color: $primary;
    border-top: 0;
  }

  h2 {
    font-weight: 700;
  }
}


#team .section-headline {
  text-transform: none;
  letter-spacing: 0;
}


/*** header ***/
/** ------------------------------------------------------------------------------- **/
/** ------------------------------------------------------------------------------- **/

.navbar {
  .navbar-brand {
    padding-top: 8px;
  }

  padding-left: 10%;
  background: $white;
  width: 100%;
  opacity: 0.9;


  .navbar-nav {

    .nav-link {
      font-weight: 700;
      text-transform: uppercase;
      padding: 54px 35px 0 35px;


    }

    .lang-select {
      .nav-link {
        padding: 54px 5px 0 5px;
      }
    }
  }

  .navbar-toggler {
    margin: auto 0;
    height: 40px;

    span {
      display: block;
      background-color: $dark-color;
      height: 2px;
      width: 20px;
      margin-top: 4px;
      margin-bottom: 4px;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      position: relative;
      left: 0;
      opacity: 0.8;

      &.btn-close-icon:nth-child(1),
      &.btn-close-icon:nth-child(3) {
        -webkit-transition: transform .35s ease-in-out;
        -moz-transition: transform .35s ease-in-out;
        -o-transition: transform .35s ease-in-out;
        transition: transform .35s ease-in-out;
      }

      &.btn-close-icon:nth-child(1) {
        position: relative;
        left: 0;
        top: 10px;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
        opacity: 0.8;
      }

      &.btn-close-icon:nth-child(2) {
        height: 12px;
        visibility: hidden;
        background-color: transparent;
      }

      &.btn-close-icon:nth-child(3) {
        position: relative;
        left: 0;
        bottom: 12px;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
        opacity: 0.8;
      }
    }
  }
}


/*** Section-1 ***/
/** ------------------------------------------------------------------------------- **/
/** ------------------------------------------------------------------------------- **/
#bemutatkozas {
  padding: 0;

  &.container-fluid-pic {
    background: url("/assets/img/header.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
  }

  .lead-text {
    font-weight: 700;
  }
}

.lead-block {
  min-height: 600px;
  padding: 24rem 8rem 18rem 8rem;
  color: white;
  background: rgba(0, 0, 0, 0.6);
  overflow: hidden;
  z-index: 2;
}


/*** Section-2: About us ***/
/** ------------------------------------------------------------------------------- **/
/** ------------------------------------------------------------------------------- **/
.unique-text {
  font-family: 'Playfair Display', serif;
  font-size: 2.5em;
}

/*** Section-3: Machine center***/
/** ------------------------------------------------------------------------------- **/
/** ------------------------------------------------------------------------------- **/
//#machineCenter {
//  background-color: $primary;
//}
//
//.mach-desc {
//  padding-top: 25%;
//}

/*** Section-4: Competences ***/
/** ------------------------------------------------------------------------------- **/
/** ------------------------------------------------------------------------------- **/


.competences {
  background: url("/assets/img/competences.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  padding: 0;

  .vertical-align {
    border-left: 5px solid $secondary;
    padding-left: 2rem;
    height: 60px;
    float: left;
  }

  .overlay-white {
    background: rgba(255, 255, 255, 0.8);
    overflow: hidden;
    z-index: 2;
    height: 100%;
    padding-bottom: $spacer-padding-bottom;
  }

  p {
    padding-left: 2.3rem;
  }
}


button:focus {
  outline: 0 dotted;
  //outline: 0 auto -webkit-focus-ring-color;
}


/*** CAREER ***/
.career .section-headline {
  padding: 96px 0 50px 0;
}

.career, #team {
  background-color: #ececec;

  .list-unstyled {
    li {
      padding: 3px;
    }
  }

  .brw-btn {
    margin-top: 35px;
  }

  .container h2 {
    padding-bottom: 48px;
  }
}


/*** Contact ***/
.brw-title-color {
  color: $secondary;
}

.letter-s-title {
  color: $dark-color;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 2px;
}

.contact-sub-title {
  color: $primary;
}

/*** Blog ***/

.blog .col-sm-12 {
  position: relative;
}

.blog .col-sm-12 .btn-block {
  position: absolute;
  bottom:0;
  left:2.5%;
  max-width: 95%;
}


/*** Footer ***/
/** ------------------------------------------------------------------------------- **/
/** ------------------------------------------------------------------------------- **/

#footer {
  background-color: $secondary;
  color: $white;

  p {
    margin-bottom: 0;

    a {
      color: $white;

      &:hover {
        color: $white;
      }
    }
  }
}


/*** back-to-top css ***/
.button {
  display: inline-block;
  background-color: $primary;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 4px;
  margin: 30px;
  position: fixed;
  bottom: 30px;
  right: 0;
  transition: background-color .3s;
  z-index: 1000;
  line-height: 60px;

  &:hover {
    cursor: pointer;
    background-color: $dark-color;
  }

  &:active {
    background-color: #555;
  }

  i {
    color: $white;
  }
}


/*** Machine box css ***/
/** ------------------------------------------------------------------------------- **/
/** ------------------------------------------------------------------------------- **/
.card {
  border: none;

  &.post-bg {
    background-color: $primary;
    color: $white;
    height: 100%;
    padding: 10% 0;
  }

  &:hover,
  &.hover {
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
  }

  .thumbnail {
    background: $dark-color;
    overflow: hidden;
    border-top-right-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    background: #fff;
    img {
      min-height: 360px;
      -webkit-transition: all 0.3s linear 0s;
      -moz-transition: all 0.3s linear 0s;
      -ms-transition: all 0.3s linear 0s;
      -o-transition: all 0.3s linear 0s;
      transition: all 0.3s linear 0s;

      &:hover,
      &.hover {
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        transform: scale(1.1);
        opacity: 0.6;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
      }
    }
  }

  .card-body {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #e6e7e8;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
    -moz-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
    -ms-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
    -o-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
    transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;

    .card-title {
      margin: 0;
      color: $secondary;
      font-size: 28px;
      font-weight: 400;
      letter-spacing: 1px;
    }

    .card-subtitle {
      padding: 5px 0 0;
      color: #4d4d4f;
      font-size: 20px;
      font-weight: 500;
    }

    .card-text {
      padding-top: 7px;
      display: none;
      color: #4d4d4f;
      font-size: 16px;
      line-height: 1.8em;
    }
  }
}


//
//@media (min-width: 1600px) {
//  #machineCenter img {
//    width: 200px;
//  }
//}


@media (min-width: 1323px) {
  .navbar {
    padding-left: 7%;
  }

  //#machineCenter .brw-container {
  //  padding: 96px 8rem;
  //}
}


@media (max-width: 1280px) {
  .about-us .section-headline {
    padding-bottom: 50px;
  }
  .card.post-bg {
    background-color: $primary;
    color: $white;
    height: auto;
    padding: 6% 0;
  }
}


@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navbar {
    padding-left: 5%;
  }
  .navbar-light .navbar-nav .nav-link {
    padding: 55px 8px 0 8px;
  }
}


@media (max-width: 992px) {
  .navbar {
    padding-left: 3%;

    .navbar-nav {
      .nav-link {
        padding: 1rem 0;
      }
    }
  }

  .lead-block {
    min-height: 600px;
    padding: 12rem 2rem 0 2rem;

    .lead-text {
      font-size: 24px;
    }
  }


  .unique-text {
    font-size: 1.7rem;
    text-align: center;
  }
  .section-headline h2 {
    font-size: 1.7rem;
  }

  .career .container .list h2 {
    padding-top: 48px;
  }

  .machine-box .card .card-body {
    position: relative;
  }
}

@media (min-width: 768px) {
  .card .card-body .card-subtitle {
    height: 40px;
  }
}


@media (max-width: 576px) {
  .section-headline {
    letter-spacing: 15px;

    h2 {
      font-size: 1.2rem;
    }
  }
  .about-img img {
    width: 80%;
  }
}


@media (min-width: 320px) {
  .machine-box .row .col-sm-12.col-md-12.col-lg-6 {
    padding: 15px;
  }
}

#status {
  font-weight: bold;

  .error {
    color: red;
  }
}

#contactform {
  .error {
    color: red;
  }
}

.form-group {
  ul {
    list-style: none;
    margin: 5px 0;
    padding: 0;
    color: red;
  }
}

#map {
  height: 400px;
}


.without-header {
  padding-top: 110px;
}
